//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import { dropdown, modal } from 'vue-strap'
export default {
  name: 'Studio',
  components: {
    dropdown,
    mapGetters,
    mapState,
    modal,
    rooms: () => import(/* webpackChunkName: "rooms" */ '@/views/company/Company_Element_Rooms')
  },
  data: function () {
    return {
      loaded: false,
      pxperminute: 0,
      edittimeslot: false,
      showroom: false,
      edittimeslotdata: [],
      editroomdata: [],
      windowWidth: 0,
      windowHeight: 0,
      openinghoursactiveClass: 'carddisabled',
      roomsactiveClass: 'carddisabled',
      servicesvalue: '',
      roomsvalue: '',
      roomelementusage: 'edit',
      weekdaysen: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    }
  },
  created: function () {
    if (typeof this.$store.state.EditorLocation === 'undefined' || this.$store.state.EditorLocation === '') this.$router.push('/unternehmen/basisinformationen/')
  },
  mounted: function () {
    this.loaded = true
  },
  beforeDestroy () {
  },
  computed: {
    ...mapState({
      dataobj: state => state.EditorLocation,
      activecompanyid: state => state.config.ActiveCompany,
      activedate: state => state.dateconfig.ActiveDate
    })
  },
  methods: {
    updatelocation: function () {
      this.dataobj.created = new Date(this.dataobj.created).formatDate("YYYY-MM-DD HH:mm:ss")
      this.dataobj.updated = new Date(this.dataobj.updated).formatDate("YYYY-MM-DD HH:mm:ss")
      this.dataobj.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateLocation',this.dataobj)
      return false
    },
    closemodal () {
      this.edittimeslot = false
      document.body.classList.remove('modal-open')
    },
    editroom: function (room) {
      this.editroomdata = room
      console.log('Editroom')
      console.log(room)
      this.showroom = true
    },
    changeview: function () {
      this.$router.push('/unternehmen/basisinformationen')
    },
    saveopeninghour: function () {
      console.log('Save')
      this.$socket.emit('UpdateOpeningHours', this.edittimeslotdata)
      this.edittimeslot = false
    }
  }
}
