<template>
<div class="wrapper">
  <div v-if="company" class="animated fadeIn" style="margin-top:24px;">
    <div class="row">
      <div class="col-sm-6" style="padding:16px;">
        <h2>{{ company.name }}</h2>
      </div>
      <div class="col-sm-6" style="padding:12px;">
        <button type="button" class="btn btn-success" style="float:right; margin: 6px;" @click="save()"><i class="fa fa-magic"></i>&nbsp; Speichern</button>
      </div>
    </div>
    <tabs nav-style="tabs" justified>
      <tab header="Basisinformationen">
        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-header">
                <strong>Basisinformationen</strong>
              </div>
              <div class="card-block">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <label for="firstname" class="col-md-3 form-control-label">Firma</label>
                      <div class="col-md-9">
                        <input v-if="company" type="text" class="form-control" id="firstname" placeholder="Firma" :v-bind-value=company.name v-model=company.name>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <label for="street" class="col-md-3 form-control-label">Straße</label>
                      <div class="col-md-9">
                        <input type="text" class="form-control" id="street" placeholder="Straße" :v-bind-value=company.street v-model=company.street>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <label for="pc" class="col-md-3 form-control-label">PLZ</label>
                      <div class="col-md-4">
                        <input type="text" class="form-control" id="pc" maxlength="5" placeholder="PLZ" :v-bind-value=company.postcode v-model=company.postcode>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <label for="city" class="col-md-3 form-control-label">Ort</label>
                      <div class="col-md-9">
                        <input type="text" class="form-control" id="city" placeholder="Ort" :v-bind-value=company.city v-model=company.city>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <label for="country" class="col-md-3 form-control-label">Land</label>
                      <div class="col-md-9">
                        <input type="text" class="form-control" id="country" placeholder="Deutschland" :v-bind-value=company.country v-model=company.country>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group row">
                      <label for="taxnumber" class="col-md-3 form-control-label">USt.-Ident.-Nr.</label>
                      <div class="col-md-9">
                        <input type="text" class="form-control" id="taxnumber" placeholder="" :v-bind-value=company.taxnumber v-model=company.taxnumber>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--/.col-->
          <div class="col-sm-6">
            <div class="card">
              <div class="card-header">
                <strong>Niederlassungen</strong>
                <button type="button" class="btn btn-secondary" style="padding: 6px; margin: 0px; float: right;" @click="showlocationsoptions = !showlocationsoptions"><i class="fa fa-gears"></i></button>
                <button v-if="showlocationsoptions" type="button" class="btn btn-secondary" v-bind:class="{ active: locationarchivestatus === 1}" @click="locationarchivestatus = 1;" style="padding: 6px; margin: 0px; float: right;">Archive</i></button>
                <button v-if="showlocationsoptions" type="button" class="btn btn-secondary" v-bind:class="{ active: locationarchivestatus === 0}" @click="locationarchivestatus = 0;" style="padding: 6px; margin: 0px; float: right;">Aktiv</button>
              </div>
              <div class="card-block">
                <div class="row">
                  <div class="col-sm-12">
                    <table class="table">
                      <thead>
                        <tr>
                          <td width="70%">Name</td>
                          <td width="30%" v-if="showlocationsoptions">Aktion</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="location in locationsbycompany" v-if="location.archive === locationarchivestatus">
                          <td width="70%">{{ location.name }}</td>
                          <td width="30%" v-if="showlocationsoptions === true">
                            <button type="submit" class="btn btn-primary" @click="editlocation(location)"><i class="fa fa-edit"></i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer" v-bind:class=locationactiveClass>
                <button type="submit" class="btn btn-success"><i class="fa fa-plus-circle"></i></button>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="card">
              <div class="card-header">
                <strong>Verbundene Unternehmen</strong>
              </div>
              <div class="card-block">
                <div class="row">
                  <div class="col-sm-12">
                    <ul class="list-group">
                      <li class="list-group-item" v-for="connectedcompany in connectedcompanies">{{ connectedcompany.name }}</li>
                    </ul>
                    <p>Weiteres Unternehmen anbinden</p>
                    <p>Verbindungstoken eingeben</p>
                    <p>Verbindungstoken erstellen</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab header="Mitarbeiter" @click="showresources()">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <strong>Übersicht</strong>
              <button type="button" class="btn btn-secondary" style="padding: 6px; margin: 0px; float: right;" @click="togglearchiveview()">Inaktiv</i></button>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="col-sm-12">
                  <table class="table">
                    <thead>
                      <tr>
                        <td width="10%"></td>
                        <td width="20%">Name</td>
                        <td width="20%">Zugangslevel</td>
                        <td width="10%">Aktiv</td>
                        <td width="10%">Aktion</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="resource in resourcesbycompany">
                        <td width="10%">
                          <avatar :username="resource.firstname + ' ' + resource.surname"></avatar>
                        </td>
                        <td width="20%">
                          <p>{{ resource.firstname }} {{ resource.surname }}</p>
                        </td>
                        <td width="20%">
                          <p v-if="getuseracllevel(resource, 'name') === false"> Kein Zugangslevel angelegt.</p>
                          <p v-if="resource.userid.length || getuseracllevel(resource, 'name') !== false"> {{ getuseracllevel(resource, 'name') }}</p>
                          <p v-if="resource.userid.length === 0">Kein Online-Zugang angelegt.</p>
                        </td>
                        <td width="10%">
                          <label class="switch switch-default switch-pill" v-bind:class="{ 'switch-success': resource.archive === 0 }">
                            <input type="checkbox" class="switch-input" :checked="resource.archive === 0" @click="toggleresourcearchive(resource)">
                            <span class="switch-label"></span>
                            <span class="switch-handle"></span>
                          </label>
                        </td>
                        <td width="10%">
                          <button type="submit" class="btn btn-primary" @click="editstaff(resource)"><i class="fa fa-edit"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row" v-if="showothercompanyresource === 1">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-1 align-self-center">
                      <button type="submit" class="btn btn-warning" @click="showothercompanyresource = +!showothercompanyresource"><i class="fa fa-minus-circle"></i></button>
                    </div>
                    <div class="col-sm-4">
                      <label for="Resources" class="form-control-label">Resourcen aus verbundenem Unternehmen:</label>
                      <multiselect v-model="resourceofconnectedcompanies" @input="" :multiple="false" track-by="id" label="firstname" :options="getresourcesofconnectedcompanies()" :close-on-select="true" :hide-selected="true" :clear-on-select="true">
                      </multiselect>
                    </div>
                    <div class="col-sm-4 align-self-center">
                      <button type="submit" class="btn btn-success" @click="takeoverresourcefromconnectedcompany()">Hinzufügen</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="shownewresource === 1">
                <div class="col-sm-12">
                  <div class="row">
                    <div class="col-sm-3">
                      <label for="firstname" class="form-control-label">Vorname</label>
                      <input type="text" class="form-control" id="firstname" placeholder="Vorname" :v-bind-value=newresource.firstname v-model=newresource.firstname>
                    </div>
                    <div class="col-sm-3">
                      <label for="surname" class="form-control-label">Nachname</label>
                      <input type="text" class="form-control" id="surname" placeholder="Nachname" :v-bind-value=newresource.surname v-model=newresource.surname>
                    </div>
                    <div class="col-sm-3">
                      <label for="email" class="form-control-label">Email</label>
                      <input type="text" class="form-control" id="email" placeholder="Email" :v-bind-value=newresource.email v-model=newresource.email>
                    </div>
                    <div class="col-sm-3 align-self-center">
                      <button type="submit" class="btn btn-success" @click="savenewresource()">Anlegen</button>
                      <button type="submit" class="btn btn-warning" @click="shownewresource = +!shownewresource"><i class="fa fa-minus-circle"> Abbrechen</i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-12 align-self-center">
                  <button type="submit" class="btn btn-success" @click="shownewresource = +!shownewresource"><i class="fa fa-plus-circle"></i> Neu</button>
                  <button type="submit" class="btn btn-success" @click="showothercompanyresource = +!showothercompanyresource"><i class="fa fa-plus-circle"></i> Resource aus anderem Unternehmen</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <strong>Benutzergruppen</strong>
            </div>
            <div class="card-block">
              <div class="row">
                <div class="col-sm-12">
                  <accordion :one-at-atime="true" type="info" v-if="accesscontrollevelbyordering.length > 0">
                    <panel v-for="level in accesscontrollevelbyordering" :key=level.id>
                      <div slot="header" class="row">
                        <div class="col-md-6">{{ level.name }}</div>
                        <div class="col-md-6"></div>
                      </div>
                      <div class="row" style="margin-top:24px;">
                        <div class="col-md-12">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-2">Name</div>
                                  <div class="col-md-10">
                                    <input type="text" class="form-control" id="name" placeholder="Gruppenname" :v-bind-value=level.name v-model=level.name :disabled="level.role === 'admin'" @change="savelevel(level)">
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li v-for="accessright in acllevelrights" v-if="accessright.aclid === level.id" class="list-group-item">
                              <div class="container">
                                <div class="row">
                                  <div class="col-md-2">{{ getactionlanguagetag(accessright.action) }}</div>
                                  <div class="col-md-10">
                                    <div class="row" v-if="accessright.action === 'views'">
                                      <div v-for="component in appcomponents" class="col-md-3">
                                        <input type="checkbox" :id="'checkbox'+component" :value="component" v-model="appcomponentsvalueobject[level.id]" @change="saveappcomponent(accessright)" :disabled="level.role === 'admin'">
                                        <label :for="'checkbox'+component">{{ component }}</label>
                                      </div>
                                    </div>
                                    <div class="row" v-if="accessright.action === 'locations'">
                                      <div v-for="location in locationsbycompany" class="col-md-4">
                                        <input type="checkbox" :id="'checkbox'+location.id" :value="location.id" v-model="locationsvalueobject[level.id]" @change="savelocation(accessright)" :disabled="level.role === 'admin'">
                                        <label :for="'checkbox'+location.id">{{ location.name }}</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </panel>
                  </accordion>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-sm-12 align-self-center">
                  <button type="submit" class="btn btn-success" @click="createnewuserlevel()"><i class="fa fa-plus-circle"></i> Neu</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab header="Equipment">
        <div class="card-header">
          <strong>Übersicht</strong>
        </div>
        <div class="card-block">
          <div class="row">
            <div class="col-sm-12">
              <accordion :one-at-atime="true" type="info">
                <panel v-for="kind in filterequipmentbycompany" :key=kind.id>
                  <div slot="header" class="row">
                    <div class="col-md-6">{{ kind.description }}</div>
                    <div class="col-md-6"></div>
                  </div>
                  <div class="row" style="margin: 24px 0px 24px 0px; padding-left: 24px;">
                    <div class="col-md-12">
                      <div class="row">
                        <label for="description" class="col-md-2 form-control-label">Bezeichnung</label>
                        <div class="col-md-6">
                          <input type="text" class="form-control" id="description" placeholder="..." v-model="kind.description" @keyup="editkindofequipment(kind.id)">
                        </div>
                        <div class="col-md-4">
                          <button v-if="editkindofequipmentids.includes(kind.id)" type="submit" class="btn btn-success" @click="saveeditkindofequipment(kind)"><i class="fa fa-magic"></i> Speichern</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin: 24px 0px 24px 0px; padding-left: 24px;">
                    <div class="col-md-12">
                      <ul class="list-group" v-for="item in getequipmentitemsbycategory(kind.id)">
                        <li class="list-group-item">
                          <div v-if="!edititemsids.includes(item.id)" class="col-md-6" @click="edititem(item.id)">{{ item.description }}</div>
                          <div v-if="edititemsids.includes(item.id)" class="col-md-6"><input type="text" class="form-control" id="description" placeholder="..." v-model="item.description"></div>
                          <div class="col-md-6">
                            <button v-if="edititemsids.includes(item.id)" type="submit" class="btn btn-success pull-right" @click="saveedititem(item)"><i class="fa fa-magic"></i> Speichern</button>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-12" style="margin-top:24px;">
                      <button type="submit" class="btn btn-success" @click="newequipmentitem(kind.id)"><i class="fa fa-plus-circle"></i></button>
                    </div>
                  </div>
                </panel>
              </accordion>
              <div class="row">
                <div class="col-md-12" style="margin-top:24px;">
                  <button type="submit" class="btn btn-success" @click="newkindofequipment()"><i class="fa fa-plus-circle"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </tab>
      <tab header="Integrationen">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h3>Kommunikation</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ul class="list-group">
                <li v-for="integration in integrations" v-if="integration.area === 'communication'" :key="integration.name" class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="image-parent">
                    <img :src=integration.img width="100" class="img-fluid" alt="quixote">
                  </div>
                  ZOOM.US
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container" style="margin-top:48px;">
          <div class="row">
            <div class="col-md-12">
              <h3>Payment</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ul class="list-group">
                <li v-for="integration in integrations" v-if="integration.area === 'payment'" :key="integration.name" class="list-group-item d-flex justify-content-between align-items-center">
                  <div class="image-parent">
                    <img :src=integration.img width="100" class="img-fluid" alt="quixote">
                  </div>
                  PAYPAL
                </li>
              </ul>
            </div>
          </div>
        </div>
      </tab>
    </tabs>
  </div>
  <modaleditlocation v-if="showmodaleditlocation" :dataobject=editlocationdata :showmodaleditlocation=showmodaleditlocation v-model=showmodaleditlocation :usage=modallocationusage />
  <modalnewemployee v-if="showmodalnewemployee" :getmodal=showmodalnewemployee v-model="showmodalnewemployee" />
</div>
</template>

<script>
import { modal, accordion, panel, tabs, tab } from 'vue-strap'
import Avatar from 'vue-avatar'
import { mapState, mapGetters } from 'vuex'
// Helper to create uid.
function s4 () {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}
const uid = function () {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4()
}
export default {
  name: 'CompanyBasicInformation',
  components: {
    mapState,
    mapGetters,
    modal,
    modaleditlocation: () => import(/* webpackChunkName: "modaleditlocation" */ '@/components/elements/modaleditlocation'),
    modalnewemployee: () => import(/* webpackChunkName: "modalnewemployee" */ '@/views/company/Company_Staff_Modal_NewEmployee'),
    'avatar': Avatar,
    accordion,
    panel,
    tabs,
    tab
  },
  data: function () {
    return {
      editkindofequipmentids: [],
      edititemsids: [],
      rows: '',
      locationarchivestatus: 0,
      showlocationsoptions: false,
      locationactiveClass: 'carddisabled',
      resourceactiveClass: 'carddisabled',
      showmodaleditlocation: false,
      showmodalnewemployee: false,
      editlocationdata: [],
      modallocationusage: 'edit',
      resourcesfilterarchive: 0,
      resourcesbycompany: [],
      shownewresource: 0,
      showothercompanyresource: 0,
      resourceofconnectedcompanies: {},
      resourcesofconnectedcompanies: [],
      clientgroupsbycompany: [],
      groupsorting: [],
      dragOptions: {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      },
      newresource: {
        firstname: '',
        surname: '',
        email: ''
      },
      appcomponents: [],
      appcomponentsvalueobject: [],
      locationsvalueobject: [],
      locationsbycompany: [],
      companyacllist: [],
      resourcesacllist: [],
      active: false
    }
  },
  created: function () {
    this.$router.options.routes[0].children.forEach(route => {
      if (route.name !== 'Login') {
        this.appcomponents.push(route.name)
      }
    })
    console.log(this.appcomponents)
  },
  computed: {
    ...mapGetters({
      company: 'getActiveCompanyData',
      connectedcompanies: 'getConnectedCompanies',
      resources: 'getresourcesbycompany',
    }),
    ...mapState({
      integrations: state => state.integrations,
      acllist: state => state.Acl.levels,
      companiesacllist: state => state.tables.companies_acl_level,
      acllevelrights: state => state.tables.companies_acl_level_rights,
      acllevelsmapping: state => state.tables.companies_acl_mapping,
      activecompany: state => state.config.ActiveCompany,
      locations: state => state.tables.locations,
      resources: state => state.tables.resources_companies,
      clientgroups: state => state.tables.companies_clients_groups,
      equipment_kindsof: state => state.tables.equipment_kinds,
      equipment_items: state => state.tables.equipment_items
    }),
    accesscontrollevelbyordering: function () {
      let list = []
      list = this.companyacllist.sort(function (a,b) {
        return parseInt(a.ordering) - parseInt(b.ordering)
      })
      return list
    },
    filterequipmentbycompany: function () {
      return this.equipment_kindsof.filter(equipment => equipment.companyid === this.activecompany)
    }
  },
  mounted: function () {
    console.log('mounted')
    this.resourcesbycompany = this.getresourcesbyactivecompany()
    this.locationsbycompany = this.getlocationsbycompany()
    this.companyacllist = this.getcompanyacllist()
    console.log(this.companyacllist)
    this.resourcesacllist = this.getresoucesacllistbycompany()
    this.setvalueobjects()
    this.clientgroupsbycompany = this.getclientgroupsbycompany()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.active = true
    })
  },
  beforeRouteLeave (to, from, next) {
    next(vm => { this.active = false })
  },
  beforeDestroy: function () {
    this.resourcesbycompany = null
    this.clientgroupsbycompany = null
  },
  destroyed: function () {
    console.log('Destroyed')
  },
  methods: {
    getlocationsbycompany: function () {
      return this.locations.filter(location => location.companyid === this.activecompany)
    },
    getresoucesacllistbycompany: function () {
      let filtered = []
      let companyacllevel = this.companiesacllist.filter(acllevel => acllevel.companyid === this.activecompany)
      for (let i = 0; i < companyacllevel.length; i++) {
        let result = this.acllevelsmapping.filter(aclmapping => aclmapping.acllevelid === companyacllevel[i].id)
        filtered = filtered.concat(result)
      }
      companyacllevel = null
      return filtered
    },
    newkindofequipment: function () {
      let kind = {
        id: uid(),
        companyid: this.$store.state.config.ActiveCompany,
        description: 'Neue Trainingsart',
        archive: 0,
        created: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
        createdbyid: this.$store.state.Userdata.datasource.id,
        updated: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
        updatedbyid: this.$store.state.Userdata.datasource.id
      }
      this.$socket.emit('NewKindOfEquipment',kind)
      return false
    },
    editkindofequipment: function (id) {
      if (!this.editkindofequipmentids.includes(id)) {
        this.editkindofequipmentids.push(id)
      }
      return false
    },
    saveeditkindofequipment: function (kind) {
      kind.created = new Date(kind.created).formatDate("YYYY-MM-DD HH:mm:ss")
      kind.updated = new Date(kind.updated).formatDate("YYYY-MM-DD HH:mm:ss")
      kind.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateKindOfEquipment',kind)
      for (let i = 0; i < this.editkindofequipmentids.length; i++) {
        if (this.editkindofequipmentids[i] === kind.id) {
          this.editkindofequipmentids.splice(i, 1)
        }
      }
      return false
    },
    newequipmentitem: function (kindid) {
      let item = {
        id: uid(),
        kindofequipmentid: kindid,
        locationids: '',
        roomids: '',
        description: 'Neues Sportgerät',
        archive: 0,
        created: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
        createdbyid: this.$store.state.Userdata.datasource.id,
        updated: new Date().formatDate("YYYY-MM-DD HH:mm:ss"),
        updatedbyid: this.$store.state.Userdata.datasource.id
      }
      this.$socket.emit('NewEquipmentItem',item)
      return false
    },
    edititem: function (id) {
      if (!this.edititemsids.includes(id)) {
        this.edititemsids.push(id)
      }
      return false
    },
    saveedititem: function (item) {
      item.created = new Date(item.created).formatDate("YYYY-MM-DD HH:mm:ss")
      item.updated = new Date(item.updated).formatDate("YYYY-MM-DD HH:mm:ss")
      item.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateEquipmentItem',item)
      for (let i = 0; i < this.edititemsids.length; i++) {
        if (this.edititemsids[i] === item.id) {
          this.edititemsids.splice(i, 1)
        }
      }
      return false
    },
    getequipmentitemsbycategory: function (categoryid) {
      return this.equipment_items.filter(item => item.kindofequipmentid === categoryid)
    },
    getcompanyacllist: function () {
      return this.companiesacllist.filter(level => level.companyid === this.activecompany)
    },
    savelevel: function (level) {
      level.created = new Date(level.created).formatDate('YYYY-MM-DD HH:mm:ss')
      level.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      level.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('updatecompaniesacllevel',level)
      return false
    },
    createnewuserlevel: function () {
      let newentry = {
        id: uid(),
        companyid: this.$store.state.config.ActiveCompany,
        ordering: this.companyacllist.length + 1,
        name: '',
        role: '',
        rights: '',
        archive: 0,
        created: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
        createdbyid: this.$store.state.Userdata.datasource.id,
        updated: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
        updatedbyid: this.$store.state.Userdata.datasource.id
      }
      console.log(newentry)
      this.$socket.emit('newcompaniesacllevel',newentry)
      return false
    },
    transformitemsfordb (data) {
      let result = ''
      if (typeof data !== 'undefined' && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (i > 0) {
            result = result + ',' + data[i]
          } else {
            result = data[i]
          }
        }
      }
      return result
    },
    transformitemsfordb2 (data) {
      let result = ''
      if (typeof data !== 'undefined' && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          if (i > 0) {
            result = result + ',' + data[i]
          } else {
            result = data[i]
          }
        }
      }
      return result
    },
    savelocation: function (acldata) {
      console.log(this.locationsvalueobject[acldata.aclid])
      acldata.val = this.transformitemsfordb2(this.locationsvalueobject[acldata.aclid])
      acldata.created = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      acldata.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      acldata.updatedbyid = this.$store.state.Userdata.datasource.id
      console.log(acldata)
      /// this.$socket.emit('updatecompaniesacllevelright', acldata)
      return false
    },
    saveappcomponent: function (acldata) {
      acldata.val = this.transformitemsfordb(this.appcomponentsvalueobject[acldata.aclid])
      acldata.created = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      acldata.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      acldata.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('updatecompaniesacllevelright', acldata)
      return false
    },
    setvalueobjects: function () {
      this.locationsvalueobject = []
      this.appcomponentsvalueobject = []
      for (let i = 0; i < this.companyacllist.length; i++) {
        console.log(this.getaccessrightsbylevel(this.companyacllist[i]))
        let rightslevel = Object.assign([],this.getaccessrightsbylevel(this.companyacllist[i]))
        this.locationsvalueobject[this.companyacllist[i].id] = []
        this.appcomponentsvalueobject[this.companyacllist[i].id] = []
        let locationsavailable = false
        let viewsavailable = false
        for (let i2 = 0; i2 < rightslevel.length; i2++) {
          if (rightslevel[i2].action === 'locations') {
            locationsavailable = true
            if (rightslevel[i2].val !== '') {
              let value = rightslevel[i2].val
              let valuesplit = value.split(",")
              if (valuesplit) {
                if (this.companyacllist[i].role === 'admin') {
                  console.log(rightslevel)
                  if (JSON.stringify(valuesplit) === JSON.stringify(this.locationsbycompany.map(item => (item.id)))) {
                    console.log('1 ist identisch')
                    this.locationsvalueobject[this.companyacllist[i].id] = valuesplit
                  } else {
                    console.log('1 ist nicht identisch')
                    // Update Rights
                    rightslevel[i2].val = this.locationsbycompany.map(item => (item.id)).join(",")
                    console.log('1 Anpassung rightslevel')
                    rightslevel[i2].created = new Date(rightslevel[i2].created).formatDate('YYYY-MM-DD HH:mm:ss'),
                    rightslevel[i2].updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
                    rightslevel[i2].updatedbyid = this.$store.state.Userdata.datasource.id
                    console.log(rightslevel[i2])
                    this.$socket.emit('updatecompaniesacllevelright', rightslevel[i2])
                  }
                } else {
                  this.locationsvalueobject[this.companyacllist[i].id] = valuesplit
                }
              }
              value = null
              valuesplit = null
            }
          } else if (rightslevel[i2].action === 'views') {
            viewsavailable = true
            if (rightslevel[i2].val !== '') {
              let value = rightslevel[i2].val
              let valuesplit = value.split(",")
              if (valuesplit) {
                if (this.companyacllist[i].role === 'admin') {
                  if (JSON.stringify(this.appcomponents) === JSON.stringify(valuesplit)) {
                    console.log('2 ist identisch')
                    this.appcomponentsvalueobject[this.companyacllist[i].id] = Object.assign([],valuesplit)
                  } else {
                    console.log('2 ist nicht identisch')
                    // Update rights
                    rightslevel[i2].val = this.appcomponents.map(item => (item)).join(",")
                    console.log('1 Anpassung rightslevel')
                    rightslevel[i2].created = new Date(rightslevel[i2].created).formatDate('YYYY-MM-DD HH:mm:ss'),
                    rightslevel[i2].updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
                    rightslevel[i2].updatedbyid = this.$store.state.Userdata.datasource.id
                    console.log(rightslevel[i2])
                    this.$socket.emit('updatecompaniesacllevelright', rightslevel[i2])
                  }
                } else {
                  this.appcomponentsvalueobject[this.companyacllist[i].id] = Object.assign([],valuesplit)
                }
              }
              value = null
              valuesplit = null
            }
          }
        }
        console.log('locationsavailable: ' + locationsavailable)
        console.log('viewsavailable: ' + viewsavailable)
        console.log(this.locationsvalueobject)
        if (locationsavailable === false) {
          console.log('Locations not available')
          let item = {
            id: uid(),
            aclid: this.companyacllist[i].id,
            action: 'locations',
            val: '',
            created: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            createdbyid: this.$store.state.Userdata.datasource.id,
            updated: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            updatedbyid: this.$store.state.Userdata.datasource.id
          }
          if (this.companyacllist[i].role === 'admin') {
            if (this.locationsbycompany.length > 0) {
              let result = ''
              for (let i = 0; i < this.locationsbycompany.length; i++) {
                if (i > 0) {
                  result = result + ',' + this.locationsbycompany[i].id
                } else {
                  result = this.locationsbycompany[i].id
                }
              }
              item.val = result
            }
          } else {
            if (this.locationsbycompany.length > 0) item.val = this.locationsbycompany[0].id
          }
          console.log()
          // this.$socket.emit('newcompaniesacllevelright', item)
        } else {
          // check ob alle locations enthalten sind
          console.log(this.locationsbycompany.map(item => (item.id)))
          console.log(this.locationsvalueobject[this.companyacllist[i].id])

        }
        if (viewsavailable === false) {
          console.log('Views not available')
          let item = {
            id: uid(),
            aclid: this.companyacllist[i].id,
            action: 'views',
            val: 'Kalender',
            created: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            createdbyid: this.$store.state.Userdata.datasource.id,
            updated: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            updatedbyid: this.$store.state.Userdata.datasource.id
          }
          if (this.companyacllist[i].role === 'admin') {
            for (let i = 0; i < this.appcomponents.length; i++) {
              if (i > 0) {
                item.val = item.val + ',' + this.appcomponents[i]
              } else {
                item.val = this.appcomponents[i]
              }
            }
          }
          // this.$socket.emit('newcompaniesacllevelright', item)
        }
      }
      return false
    },
    getactionlanguagetag: function (action) {
      if (action === 'locations') return 'Studios'
      if (action === 'views') return 'Ansichten'
    },
    getclientgroupsbycompany: function () {
      return this.clientgroups.filter(group => group.companyid === this.activecompany)
    },
    takeoverresourcefromconnectedcompany: function () {
      let dataobj = Object.assign({}, this.resourceofconnectedcompanies)
      dataobj.id = uid()
      dataobj.companyid = this.activecompany
      dataobj.created = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      dataobj.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      dataobj.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('NewCompanyResource', dataobj)
      this.resourceofconnectedcompanies = {}
      this.showothercompanyresource = 0
      return false
    },
    getresourcesofconnectedcompanies: function () {
      let resourcesofconnectedcompanies = this.resources.filter(resource => resource.companyid !== this.$store.state.config.ActiveCompany)
      let resourcesofowncompany = this.resources.filter(resource => resource.companyid === this.$store.state.config.ActiveCompany)
      let list = []
      for (let i = 0; i < resourcesofconnectedcompanies.length; i++) {
        let x = 0
        for (let i2 = 0; i2 < resourcesofowncompany.length; i2++) {
          if (resourcesofconnectedcompanies[i].resourceid === resourcesofowncompany[i2].resourceid) x = x + 1
        }
        if (x === 0) list.push(resourcesofconnectedcompanies[i])
      }
      return list
    },
    savenewresource: function () {
      let newmycrogymresource = {
        id: uid(),
        userid: '',
        firstname: this.newresource.firstname,
        surname: this.newresource.surname,
        street: '',
        postcode: '',
        city: '',
        country: '',
        email: this.newresource.email,
        skills: '',
        contact: '',
        archive: 0,
        created: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
        createdbyid: this.$store.state.Userdata.datasource.id,
        updated: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
        updatedbyid: this.$store.state.Userdata.datasource.id
      }
      this.$socket.emit('NewMycrogymResource', newmycrogymresource)
      let newresourcecompany = {
        id: uid(),
        resourceid: newmycrogymresource.id,
        userid: '',
        companyid: this.$store.state.config.ActiveCompany,
        firstname: this.newresource.firstname,
        surname: this.newresource.surname,
        street: '',
        postcode: '',
        city: '',
        country: '',
        email: this.newresource.email,
        skills: '',
        contact: '',
        archive: 0,
        created: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
        createdbyid: this.$store.state.Userdata.datasource.id,
        updated: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
        updatedbyid: this.$store.state.Userdata.datasource.id
      }
      this.$socket.emit('NewCompanyResource', newresourcecompany)
      this.newresource.firstname = ''
      this.newresource.surename = ''
      this.newresource.email = ''
      this.shownewresource = 0
      return false
    },
    toggleresourcearchive: function (resource) {
      resource.archive = +!resource.archive
      resource.created = new Date(resource.created).formatDate('YYYY-MM-DD HH:mm:ss')
      resource.updated = new Date(resource.updated).formatDate('YYYY-MM-DD HH:mm:ss')
      resource.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateCompanyResource', resource)
      return false
    },
    togglearchiveview: function () {
      this.resourcesfilterarchive = +!this.resourcesfilterarchive
      this.resourcesbycompany = this.getresourcesbyactivecompany()
    },
    getresourcesbyactivecompany: function () {
      return this.resources.filter(resource => resource.companyid === this.$store.state.config.ActiveCompany && resource.archive === this.resourcesfilterarchive)
    },
    getaccessrightsbylevel: function (level) {
      let rights = this.acllevelrights.filter(rights => rights.aclid === level.id)
      console.log(rights)
      console.log(typeof rights[0].val)
      return rights
    },
    save: function () {
      this.company.created = new Date(this.company.created).formatDate('YYYY-MM-DD HH:mm:ss')
      this.company.updated = new Date(this.company.updated).formatDate('YYYY-MM-DD HH:mm:ss')
      this.company.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateCompany', this.company)
    },
    getuseracllevel: function (resourceobject, parameter) {
      let resourceobj = {}
      let result = this.resourcesacllist.find(resource => resource.companyresourceid === resourceobject.id)
      if (typeof result !== 'undefined') {
        let acllevel = this.companyacllist.find(acllevel => acllevel.id === result.acllevelid)
        if (typeof result !== 'undefined') resourceobj = acllevel[parameter]
        return resourceobj
      } else {
        return false
      }
    },
    changeactivelocation () {
      if (this.locationactiveClass === 'cardactive') {
        this.locationactiveClass = 'carddisabled'
      } else {
        this.locationactiveClass = 'cardactive'
      }
    },
    editlocation: function (item, event) {
      this.$store.commit('setEditorLocation', item)
      this.$router.push('/unternehmen/studio')
    },
    editstaff: function (item, event) {
      this.$store.commit('setEditorStaff', item)
      this.$router.push('/unternehmen/mitarbeiter')
    }
  },
  watch: {
    resources: function () {
      this.resourcesbycompany = this.getresourcesbyactivecompany()
    },
    activecompany: function () {
      console.log('activecompany')
      this.resourcesbycompany = this.getresourcesbyactivecompany()
      this.clientgroupsbycompany = this.getclientgroupsbycompany()
      this.companyacllist = this.getcompanyacllist()
      this.setvalueobjects()
      this.showothercompanyresource = 0
      this.newresource.firstname = ''
      this.newresource.surename = ''
      this.newresource.email = ''
      this.shownewresource = 0
    },
    companiesacllist: function () {
      console.log('companiesacllist')
      this.companyacllist = this.getcompanyacllist()
      // this.setvalueobjects()
    },
    acllevelrights: function () {
      console.log('update Acl Level Rights')
      console.log('acllevelrights')
      this.companyacllist = this.getcompanyacllist()
      // this.setvalueobjects()
    }
  }
}
</script>
