//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'
import { dropdown, modal, tabs, tab } from 'vue-strap'
// Helper to create uid.
function s4 () {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1)
}

const uid = function () {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
    s4() + '-' + s4() + s4() + s4()
}
export default {
  name: 'CompanyStaff',
  components: {
    dropdown,
    mapGetters,
    mapState,
    modal,
    tabs,
    tab
  },
  data: function () {
    return {
      progressbardata: '',
      loaded: false,
      pxperminute: 0,
      edittimeslot: false,
      showroom: false,
      edittimeslotdata: [],
      editroomdata: [],
      windowWidth: 0,
      windowHeight: 0,
      openinghoursactiveClass: 'carddisabled',
      acllevel: [],
      activeTab: 0,
      resourcesacllist: []
    }
  },
  created: function () {
    if (!this.$store.state.EditorStaff || this.$store.state.EditorStaff === '') this.$router.push('/unternehmen/basisinformationen/')
  },
  mounted: function () {
    this.progressbardata = this.$refs['progressbar']
    this.acllevel = this.getacllevel()
    this.resourcesacllist = this.getresoucesacllistbycompany()
    console.log(this.resourcesacllist)
    this.loaded = true
    console.log(this.dataobj)
  },
  beforeDestroy () {
  },
  computed: {
    ...mapState({
      dataobj: state => state.EditorStaff,
      activedate: state => state.dateconfig.ActiveDate,
      rooms: state => state.tables.locations_rooms,
      acllist: state => state.Acl.levels,
      // acllevelrights: state => state.Acl.levels_companies,
      acllevelrights: state => state.tables.companies_acl_level,
      // acllevelsmapping: state => state.Acl.levels_mapping,
      acllevelsmapping: state => state.tables.companies_acl_mapping,
      activecompany: state => state.config.ActiveCompany
    }),
    getresourceacllevel: {
      get: function () {
        console.log(this.dataobj)
        let result = this.resourcesacllist.find(resource => resource.companyresourceid === this.dataobj.id)
        if (!result) {
          result = null
          return {id: '0', name: 'Keine Zuordnung', companyid: '', created: '', createdbyid: '', updated: '', updatedbyid: '', ordering: '', rights: '', role: ''}
        } else {
          let level = this.acllevelrights.find(level => level.id === result.acllevelid)
          result = null
          return level
        }
      },
      set: function (newValue) {
        let result = this.resourcesacllist.find(resource => resource.companyresourceid === this.dataobj.id)
        if (typeof result === 'undefined') {
          // store new entry
          let newdata = {
            id: uid(),
            companyresourceid: this.dataobj.id,
            acllevelid: newValue.id,
            archive: 0,
            created: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            createdbyid: this.$store.state.Userdata.datasource.id,
            updated: new Date().formatDate('YYYY-MM-DD HH:mm:ss'),
            updatedbyid: this.$store.state.Userdata.datasource.id
          }
          this.$socket.emit('newcompaniesaclmapping', newdata)
          return false
        } else {
          result.acllevelid = newValue.id
          result.created = new Date(result.created).formatDate('YYYY-MM-DD HH:mm:ss')
          result.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
          result.updatedbyid = this.$store.state.Userdata.datasource.id
          this.$socket.emit('updatecompaniesaclmapping', result)
          return false
        }
      }
    }
  },
  methods: {
    getresoucesacllistbycompany: function () {
      console.log(this.acllevelrights)
      console.log(this.acllevelsmapping)
      let filtered = []
      let companyacllevel = this.acllevelrights.filter(acllevel => acllevel.companyid === this.activecompany)
      for (let i = 0; i < companyacllevel.length; i++) {
        let result = this.acllevelsmapping.filter(aclmapping => aclmapping.acllevelid === companyacllevel[i].id)
        filtered = filtered.concat(result)
      }
      companyacllevel = null
      return filtered
    },
    getacllevel: function () {
      this.acllevel = []
      let noacllevel = [{id: '0', name: 'Keine Zuordnung', companyid: '', created: '', createdbyid: '', updated: '', updatedbyid: '', ordering: '', rights: '', role: ''}]
      let acllevelrights = Object.assign([],this.acllevelrights.filter(level => level.companyid === this.activecompany))
      acllevelrights.sort(function (a,b) {
        return parseInt(a.ordering) - parseInt(b.ordering)
      })
      console.log(this.acllevelrights)
      return noacllevel.concat(acllevelrights)
    },
    saveresource: function () {
      this.dataobj.created = new Date(this.dataobj.created).formatDate('YYYY-MM-DD HH:mm:ss')
      this.dataobj.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      this.dataobj.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateCompanyResource', this.dataobj)
      return false
    },
    saveresourceandclose: function () {
      this.dataobj.created = new Date(this.dataobj.created).formatDate('YYYY-MM-DD HH:mm:ss')
      this.dataobj.updated = new Date().formatDate('YYYY-MM-DD HH:mm:ss')
      this.dataobj.updatedbyid = this.$store.state.Userdata.datasource.id
      this.$socket.emit('UpdateCompanyResource', this.dataobj)
      this.$router.push('/unternehmen/basisinformationen')
      return false
    },
    customLabelservices: function ({ description }) {
      return `${description}`
    },
    customLabelrooms: function ({ description, kinddescription }) {
      return `${kinddescription} - ${description}`
    },
    changeactiveopeninghours () {
      if (this.openinghoursactiveClass === 'cardactive') {
        this.openinghoursactiveClass = 'carddisabled'
      } else {
        this.openinghoursactiveClass = 'cardactive'
      }
    },
    changeview: function () {
      this.$router.push('/unternehmen/basisinformationen')
    }
  },
  watch: {
    activecompany: function () {
      this.$router.push('/unternehmen/basisinformationen')
      this.$store.commit('changeactiveclient', '')
    },
    acllevelrights: function () {
      this.acllevel = this.getacllevel()
      this.resourcesacllist = this.getresoucesacllistbycompany()
    }
  }
}
