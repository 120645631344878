//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Services',
  components: {
    mapGetters,
    mapState,
    contractofferings: () => import(/* webpackChunkName: "contractofferings" */ '@/views/company/Company_Services_Element_ContractOfferings'),
    kindsofservices: () => import(/* webpackChunkName: "kindsofservices" */ '@/views/company/Company_Services_Element_KindsOfServices')
  },
  data () {
    return {
      active: false
    }
  },
  created: function () {
  },
  computed: {
    ...mapGetters({
      services: 'getservices'
    }),
    ...mapState({
      kindsofservices: state => state.tables.services_kinds,
      scopeofservice: state => state.ScopeOfService,
      contracts: state => state.tables.contracts_offered,
      servicespezificationruntimes: state => state.ServiceSpezification.filter((s) => s.dimension === 'time'),
      servicespezificationquantitys: state => state.ServiceSpezification.filter((s) => s.dimension === 'quantity')
    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.active = true
    })
  },
  beforeRouteLeave (to, from, next) {
    next(vm => { this.active = false })
  },
  methods: {
  }
}
